@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 346.8 77.2% 49.8%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 346.8 77.2% 49.8%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 346.8 77.2% 49.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .home-scrollbar::-webkit-scrollbar {
    width: 15px;
  }
  .home-scrollbar::-webkit-scrollbar-button {
    max-height: 8px;
    background: #be1b1b2a;
  }

  .home-scrollbar::-webkit-scrollbar-thumb {
    background: #be1b1b94;
  }

  @supports (scrollbar-color: auto) {
    .home-scrollbar {
      scrollbar-color: #be1b1b94 #be1b1b2a;
    }
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cross {
  border: 1px dashed black;
  opacity: 0.5;
}
/* .cross::before {
  content: '';
  width: 29%;
  border-bottom: 1px solid black;
  position: absolute;
  right: calc(44% - 10px);
  top: calc(40% - 10px); 
  transform-origin: top right;
  transform: skewY(-43deg);
} */
.cross-img {
  position: relative;
  display: inline-block;
  /* border: 1px dashed black !important; */
  opacity: 0.5;
}
.cross-img::before {
  content: '';
  width: 64%;
  border-bottom: 1px solid black;
  position: absolute;
  right: calc(44% - 10px); /* Adjusted for padding */
  top: calc(40% - 10px); /* Adjusted for padding */
  transform-origin: top right;
  transform: skewY(-43deg);
}
.ofs-overlay-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; /* sqrt(2) * 100% */
  height: 2%;
  background: #7f7f7f;
  transform: translate(-55%, -50%) rotate(-35deg);
  transform-origin: center;
}
.ofs-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; /* sqrt(2) * 100% */
  height: 1.5%;
  background: #7f7f7f;
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: center;
}
